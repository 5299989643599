<template>
  <div class="photo_wall">
      <div class="content">
          <div v-for="(row,index) in guanwangList" :key="index">
            <img :src="row.src"  style="width: 85%;" />
          </div>
      </div>
  </div>


</template>

<script>
export default{
  data(){
    return{
      guanwangList:[
          {
            src: "https://abc.xnrun.com/image/default/1B01D99F162A41BCB28C604AAD1B154F-6-2.png",
          },
          {
            src: "https://abc.xnrun.com/image/default/990098D169624A618F2ECEE9EF2D9818-6-2.png",
          },
          {
            src:'https://abc.xnrun.com/image/default/9A140C5904D5466A8B4EEE4C1D88035E-6-2.png'
          },
          {
            src:'https://abc.xnrun.com/image/default/D773C38B84AD42BFAA59BE2D8A1A5946-6-2.png'
          },
          {
            src: "https://abc.xnrun.com/image/default/496CD7B9092046AAA4F448D25022546E-6-2.png",
          },
          {
            src: "https://abc.xnrun.com/image/default/958294008620464BB4D76298394DBD6A-6-2.png",
          },
          {
            src: "https://abc.xnrun.com/image/default/5E68302525A34E94A071F7DD8A943F69-6-2.png",
          },
          {
            src: "https://abc.xnrun.com/image/default/00D46E7352484EED9213416775EF2138-6-2.png",
          }

      ],
    }
  },
  created() {
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">

</style>
